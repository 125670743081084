import React from 'react'
import Layout from '../components/Layout.js'
import { Center, Gutter } from '@patomation/react-ui-components'

const FourOhFour = () =>
<Layout>
  <Center>

    <h1 style={{textAlign: 'center'}}>404</h1>

      <Gutter />

    <p style={{textAlign: 'center'}}>Page Not Found</p>

  </Center>
</Layout>
export default FourOhFour
